<template>
  <div class="w-full bg-gray-50 p-4 flex flex-col">
    <div class="flex flex-row">
      <div class="w-1/3 p-2 border-2 border-white rounded bg-white text-gray-600">
        <div class="text-blue-700 pb-2 font-bold"><i class="fal fa-user"></i> Individual</div>
        {{ lead().customer.title }} {{ lead().customer.firstName }} {{ lead().customer.lastName }}<br/>
        {{ lead().customer.address }} {{ lead().customer.number }} {{ lead().customer.box }}<br/>
        {{ lead().customer.postCode }} {{ lead().customer.city }}<br/>
        {{ lead().customer.email }}<br/>
        {{ lead().customer.telephone }} - {{ lead().customer.mobile }}
      </div>
      <div class="ml-4 w-1/3 p-2 border-2 border-white rounded bg-white text-gray-600">
        <div class="text-blue-700 pb-2 font-bold"><i class="fal fa-building"></i> Company</div>
        {{ lead().customer.companyName }}<br/>
        {{ lead().customer.companyVat }}<br/>
        {{ lead().customer.companyAddress }} {{ lead().customer.companyNumber }} {{ lead().customer.companyBox }}<br/>
        {{ lead().customer.companyPostCode }} {{ lead().customer.companyCity }}<br/>
        {{ lead().customer.companyEmail }}<br/>
        {{ lead().customer.companyTelephone }}
      </div>
      <div class="ml-4 w-1/3 p-2 border-2 border-gray-200 rounded">
        <div class="text-gray-500 pb-2 font-bold"><i class="fal fa-user-tie"></i> Salesman</div>
        <span class="font-bold text-gray-700">{{ lead().context.subsidiary.name }}</span><br/>
        <span class="font-medium">{{ lead().context.brand.name }}</span><br/>
        <hr/>
        {{ lead().context.user.first_name }} {{ lead().context.user.last_name }}<br/>
        {{ lead().customer.email }}<br/>
        {{ lead().customer.companyTelephone }}
      </div>
    </div>
    <div class="w-full text-gray-400 pt-4">
      <div class="w-full pb-2 text-lg text-blue-600">
        <span class="font-bold">{{ lead().condition.car.makeText}}</span> {{ lead().condition.car.modelText}}<br/>
        <span class="text-gray-800">{{ lead().condition.car.versionText}}</span>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">Fuel</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.fuelTypeText}}</div>
        <div class="w-1/4">Power</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.powerHp}} Hp. / {{ lead().condition.car.powerKw }} Kw.</div>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">Registration</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.registration}}</div>
        <div class="w-1/4">Reference</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.reference}}</div>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">1° registration</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.firstRegistrationText}}</div>
        <div class="w-1/4">Transmission</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.gearTypeText}}</div>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">Exterior</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.colorExteriorText}}</div>
        <div class="w-1/4">Interior</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.colorInteriorText}}</div>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">Emission norm</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.emissionNormText}}</div>
        <div class="w-1/4">Emission co2</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.emissionCO2}} gr/km. {{lead().condition.car.emissionTypeText}}</div>
      </div>
      <div class="w-full flex flex-row">
        <div class="w-1/4">Doors</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.doorCount}}</div>
        <div class="w-1/4">Seats</div>
        <div class="w-1/4 text-gray-800 font-bold">{{lead().condition.car.seatCount}}</div>
      </div>
    </div>
    <div v-if="lead().condition.pictures.length>0" class="pt-4 pb-2 text-2xl text-blue-600">Pictures</div>
    <div class="bg-white flex flex-row flex-wrap rounded-lg px-2">
      <div v-for="item in lead().condition.pictures" v-bind:key="item.id" class="pr-2 pb-2 w-1/6">
        <img :src="item.thumbnailUrl" />
      </div>
    </div>
    <div class="py-4 text-2xl text-blue-600">Checks</div>
    <div class="">
      <div class="mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/2">
          <readonly-checkbox v-model="lead().condition.specification.clutch"></readonly-checkbox>
          <div class="ml-4 text-gray-700 font-medium">Clutch / flying wheel</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/2">
          <readonly-checkbox v-model="lead().condition.specification.timing_belt"></readonly-checkbox>
          <div class="ml-4">Recent distribution belt</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/2">
          <readonly-checkbox v-model="lead().condition.specification.recent_service"></readonly-checkbox>
          <div class="ml-4">Recent maintenance</div>
        </div>
      </div>
      <div class="mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <div class="ml-4 text-gray-700 font-medium">Summer tyres</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_summer_present"></readonly-checkbox>
          <div class="ml-4">Present</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_summer_mounted"></readonly-checkbox>
          <div class="ml-4">Mounted</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_summer_front"></readonly-checkbox>
          <div class="ml-4">Front +3mm</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_summer_rear"></readonly-checkbox>
          <div class="ml-4">Rear +3mm</div>
        </div>
      </div>
      <div class="mt-1 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <div class="ml-4 text-gray-700 font-medium">Winter tyres</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_winter_present"></readonly-checkbox>
          <div class="ml-4">Present</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_winter_mounted"></readonly-checkbox>
          <div class="ml-4">Mounted</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_winter_front"></readonly-checkbox>
          <div class="ml-4">Front +3mm</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_winter_rear"></readonly-checkbox>
          <div class="ml-4">Rear +3mm</div>
        </div>
      </div>
      <div class="mt-1 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <div class="ml-4 text-gray-700 font-medium">4 seasons banden</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_4seasons_present"></readonly-checkbox>
          <div class="ml-4">Present</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_4seasons_mounted"></readonly-checkbox>
          <div class="ml-4">Mounted</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_4seasons_front"></readonly-checkbox>
          <div class="ml-4">Front +3mm</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/5">
          <readonly-checkbox v-model="lead().condition.specification.tyres_4seasons_rear"></readonly-checkbox>
          <div class="ml-4">Rear +3mm</div>
        </div>
      </div>
      <div class="mt-1 pl-3 pb-3 bg-white rounded-lg flex flex-wrap">
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.spare_tyre"></readonly-checkbox>
          <div class="ml-4">Full spare tyre</div>
        </div>
      </div>
      <div class="pl-3 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.keys"></readonly-checkbox>
          <div class="ml-4">All keys</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.airco"></readonly-checkbox>
          <div class="ml-4">Airco working</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.interior_worn"></readonly-checkbox>
          <div class="ml-4">Wear traces</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.smell"></readonly-checkbox>
          <div class="ml-4">Odor nuisance</div>
        </div>
      </div>
      <div class="pl-3 mt-2 pb-3 bg-white rounded-lg flex flex-wrap text-sm">
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.registration_form"></readonly-checkbox>
          <div class="ml-4">Certificate of registration</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.registration_form_bis"></readonly-checkbox>
          <div class="ml-4">Part 2</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.conformity_form"></readonly-checkbox>
          <div class="ml-4">COC</div>
        </div>
        <div class="flex flex-wrap pl-2 pt-3 w-1/4">
          <readonly-checkbox v-model="lead().condition.specification.maintenance_book_complete"></readonly-checkbox>
          <div class="ml-4">Maintenance history</div>
        </div>
      </div>
    </div>
    <div class="pt-4 text-2xl text-blue-600">Options</div>
    <div class="">
      <div v-for="group in groups" v-bind:key="group.id" class="w-full bg-gray-50 text-gray-600 text-sm">
        <div v-if="_.filter(group.children, f => featurePresent(f)).length > 0" class="pt-3">
          <div class="text-lg">{{ group.name }}</div>
        </div>
        <div v-if="_.filter(group.children, f => featurePresent(f)).length > 0" class="mt-1 p-1 pb-2 bg-white rounded-lg flex flex-wrap">
          <div v-for="feature in _.filter(group.children, f => featurePresent(f))"  v-bind:key="feature.id" class="w-1/4 pl-2 pt-2">
            <div class="px-1 h-6 flex flex-row items-center text-gray-500'">
              <span class="text-lg text-green-500"><i class="fas fa-check"></i></span>
              <span class="ml-3 text-xs truncate">{{feature.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="lead().condition.damages.length>0" class="py-4 text-2xl">Damages</div>
    <div class="flex flex-col">
      <div v-for="damage in lead().condition.damages"  v-bind:key="damage.id" class="flex flex-row p-2 mb-2 bg-white rounded-lg">
        <div class="w-64 p-3 flex h-24 justify-center items-end">
          <img :src="require(`@/assets/img/damages/img/${damage.item}.png`)" class="max-h-full max-w-full">
        </div>
        <div class="p-3 flex flex-col w-full">
          <div class="font-medium">{{ damage.locationText }}</div>
          <div class="font-medium text-gray-500">{{ damage.typeText }}</div>
          <div class="font-medium text-gray-500">{{ damage.severityText }}</div>
        </div>
        <div class="w-full">
          <div v-for="pic in damage.pictures"  v-bind:key="pic.id" class="w-64">
            <img :src="pic.thumbnailUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import readOnlyCheckbox from '@/components/readonly-checkbox'
import _ from 'lodash';
import axios from "axios";

export default {
  name: "Summary",
  components: {
    'readonly-checkbox': readOnlyCheckbox
  },
  data() {
    return {
      features: [],
      groups: []

    }
  },
  methods: {
    lead() {
        return this.$store.getters['seller/lead'];
    },
    signalLoaded() {
      this.loading = false;
      this.groups = [];
      let currentGroup = { id: 0 };
      _.each(this.features, (feature) => {
        if(currentGroup.id!==feature.group_id) {
          let newGroup = {
            id: feature.group_id,
            name: feature.group,
            children: []
          }
          this.groups.push(newGroup);
          currentGroup = newGroup;
        }
        currentGroup.children.push(feature);
      });
    },
    featurePresent(feature) {
      if(this.lead().condition.features.length===0) {
        _.each(this.lead().condition.car.equipments, (e) => {
          if (_.filter(this.lead().condition.features, ft => ft.id == e.generalId).length == 0) {
            let sel = _.filter(this.features, ft => ft.id == e.generalId);
            if(sel.length>0) this.lead().condition.features.push(sel[0]);
          }
        });
      }
      return _.filter(this.lead().condition.features, (ft) => ft.id == feature.id).length > 0;
    }

  },
  watch: {
  },
  created() {
    if(this.$store.getters['tradein/features']!==null) {
      this.features = this.$store.getters['tradein/features'];
      this.signalLoaded();
    } else {
      axios.get('/manager/fleet/features/all/').then(response => {
        if (response.status === 200) this.$store.commit('tradein/setFeatures', response.data.features);
        this.features = response.data;
        this.signalLoaded();
      });
    }
  },
}
</script>

<style scoped>

</style>