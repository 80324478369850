<template>
  <span :class="['text-xl', value !== true && value !== false ? 'fas fa-circle text-gray-200' : value === true ? 'fas fa-check text-green-400' : 'fas fa-times text-red-400']"></span>
</template>

<script>
export default {
  name: "readonly-checkbox",
  props: ['value'],
  methods: {
  },
}
</script>

<style scoped>

</style>


